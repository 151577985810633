.menu-item {
  display: flex;
  align-items: center;
  position: relative;
  height: 5rem;
  color: #b7b7b7;
  cursor: pointer;
  font-weight: bold;
  align-self: center;
  text-decoration: none !important;

  transition: color 0.2s;
}

.menu-item:hover {
  color: white;
}

.menu-item.selected-true {
  color: white;
}

.menu-item.selected-true::after {
  content: '';
  height: 4px;
  border-radius: 3px 3px 0 0;
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 0;
  background-color: #c11e2f;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  top: 50px;
  padding: 0 1rem;
  z-index: 100;
  display: block;
  background: #000000;
}
