.status-invited {
  color: var(--warning);
  font-weight: bold;
}

.status-accepted {
  color: var(--success);
  font-weight: bold;
}

.status-blocked {
  color: var(--secondary);
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .grid-rows {
    width: 900px !important;
  }
}

.grid-rows {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 1rem;
  border: 1px solid #dee2e6;
  padding: 0 1rem;
  border-radius: 5px;
  margin-bottom: 10px;
}

.month-day {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid #dee2e6;
  min-height: 40px;
}

.month-day .day {
  position: absolute;
  background: var(--primary);
  border-radius: 100%;
  padding: 3px 6px;
  font-weight: bold;
  color: white;
  top: -15px;
  left: calc(50% - 13px);
  right: 0;
  width: 26px;
  font-size: .8rem;
}

.container-day {
  position: relative;
  display: flex;
  flex-direction: column;
}

.container-day hr {
  width: 100%;
}

.day-period {
  position: absolute;
  background: var(--gray);
  border-radius: 10px;
  padding: 3px 6px;
  font-weight: bold;
  color: white;
  top: 3px;
  left: revert-layer;
  font-size: .8rem;
}

.name-holder .category {
  font-size: .8rem;
}

.name-holder .name {
  font-weight: bold;
}

.name-holder {
  position: relative;
  line-height: 1.2;
}

