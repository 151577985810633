body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #C11E2F;
}

.Kalend__NewEvent {
  display: none !important;
}

code {
  font-family: "Roboto", sans-serif;
}

.Kalend__main * {
  font-family: "Roboto", sans-serif !important;
}

.MuiPaper-root .MuiDialogContent-root {
  min-width: auto;
}

.loading-true {
  outline: none !important;
}

.tr:nth-child(even) {
  background: #eeeeee;
}

.tr:nth-child(odd) {
  background: #ffffff;
}

.text-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiTablePagination-root p {
  margin-bottom: 0;
}
