
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}

.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0,0,0,0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}

#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}

#login-form p {
  margin-bottom: 30px;
}

#login-form p.form-title {
  color: #333333;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}

.illustration-wrapper {
  display: flex;
  min-width: 512px;
  height: 512px;
  max-width: 800px;
  min-height: 100%;
  background-color: #111111;
}
.illustration-wrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    display: none;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}